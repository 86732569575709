<template>
  <b-container>
    <h2 align="center" class="mt-3" style="font-family:cursive;color:darkgoldenrod">
      <b-icon icon="display" class="mr-2"></b-icon>全局数据大屏
    </h2>
    <!-- 设定相对于视窗的尺寸为100%（满屏） -->
    <b-row class="min-vh-100">
      <b-row style="width:100%">
        <b-col class="col-3 sidegraph">
          <p class="mt-1">雷达图</p>
          <b-row class="sidecell" ref="isRadar">
          </b-row>
          <p class="mt-1">横向柱状图</p>
          <b-row class="sidecell" ref="isHorizBar">
          </b-row>
        </b-col>
        <b-col class="mt-1 col-6 middlegraph">
          <b-row style="height:20%;color:darkgoldenrod"
            class="mt-5 pt-2 d-flex justify-content-center align-items-baseline totalnum">
            <h5>数据总量：</h5>
            <h2 style="font-family:'Electronic-Font',sans-serif;" ref="isTotalNum"></h2>
            <h5>&emsp;条记录</h5>
          </b-row>
          <b-row style="height:30%" class="d-flex justify-content-center">
            <b-list-group class="the-custom-list" horizontal>
              <b-list-group-item class="mr-5">华北<br><span ref="isHuaBei"></span></b-list-group-item>
              <b-list-group-item class="ml-5">中南<br><span ref="isZhongNan"></span></b-list-group-item>
            </b-list-group>
          </b-row>
          <b-row style="height:30%" class="d-flex justify-content-center">
            <b-list-group class="the-custom-list" horizontal>
              <b-list-group-item v-if="isLargeScreen" style="margin-right:8rem">华东<br><span
                  ref="isHuaDong"></span></b-list-group-item>
              <b-list-group-item v-if="isLargeScreen" style="margin-left:8rem">西北<br><span
                  ref="isXiBei"></span></b-list-group-item>
              <b-list-group-item v-if="isMediumScreen" style="margin-right:5rem">华东<br><span
                  ref="isHuaDong"></span></b-list-group-item>
              <b-list-group-item v-if="isMediumScreen" style="margin-left:5rem">西北<br><span
                  ref="isXiBei"></span></b-list-group-item>
              <b-list-group-item v-if="isSmallScreen" class="mr-4">华东<br><span
                  ref="isHuaDong"></span></b-list-group-item>
              <b-list-group-item v-if="isSmallScreen" class="ml-4">西北<br><span ref="isXiBei"></span></b-list-group-item>
            </b-list-group>
          </b-row>
        </b-col>
        <b-col class="col-3 sidegraph">
          <!-- 文字云图需要使用第三方的包来实现（echarts-wordcloud） -->
          <p class="mt-1">文字云图</p>
          <b-row class="sidecell" ref="isWordCloud">
          </b-row>
          <p class="mt-1">水球图</p>
          <b-row class="sidecell" ref="isLiquidFill">
          </b-row>
        </b-col>
      </b-row>
      <b-row style="width:100%" class="footgraph">
        <b-col>
          <h6 align="center">竖向柱状图</h6>
          <b-row style="height:80%" ref="isVertBar">
          </b-row>
        </b-col>
      </b-row>
    </b-row>
  </b-container>
</template>

<script>
import * as echarts from 'echarts'
import { getVisualization } from '../api/visualization.js'
import 'echarts-wordcloud'
import 'echarts-liquidfill'
import { CountUp } from 'countup.js'

export default {
  data() {
    return {
      data: null,
      horizBarChart: null,
      vertBarChart: null,
      radarChart: null,
      wordCloud: null,
      liquidFill: null,
      totalNum: null,
      hb: null,
      hd: null,
      zn: null,
      xb: null
    }
  },
  created() {
    // 首次加载数据
    this.loadData();
  },
  mounted() {
    // 确定图表位置
    this.horizBarChart = echarts.init(this.$refs.isHorizBar); // 横向柱状图
    this.vertBarChart = echarts.init(this.$refs.isVertBar); // 竖向柱状图
    this.radarChart = echarts.init(this.$refs.isRadar); // 雷达图
    this.wordCloud = echarts.init(this.$refs.isWordCloud); // 文档云图
    this.liquidFill = echarts.init(this.$refs.isLiquidFill); // 水球图
    // 每3秒调用一次 loadData
    setInterval(this.loadData, 5000);
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.intervalId);
    if (this.horizBarChart) {
      this.horizBarChart.dispose();
    }
    if (this.vertBarChart) {
      this.vertBarChart.dispose();
    }
    if (this.radarChart) {
      this.radarChart.dispose();
    }
    if (this.wordCloud) {
      this.wordCloud.dispose();
    }
    if (this.liquidFill) {
      this.liquidFill.dispose();
    }

  },
  // 使用深度监听来侦听 data 对象中所有属性的变化
  watch: {
    data: {
      // 如果data变化，则运行下列函数更新图表
      handler() {
        this.renderHorizBar();
        this.renderVertBar();
        this.renderRadar();
        this.renderWordCloud();
        this.renderLiquidFill();
      },
      deep: true
    },
    totalNum: {
      handler() {
        new CountUp(this.$refs.isTotalNum, this.data.totalData.total).start();
      },
      deep: true
    },
    hb: {
      handler() {
        new CountUp(this.$refs.isHuaBei, this.data.totalData.hb).start();
      },
      deep: true
    },
    hd: {
      handler() {
        new CountUp(this.$refs.isHuaDong, this.data.totalData.hd).start();
      },
      deep: true
    },
    zn: {
      handler() {
        new CountUp(this.$refs.isZhongNan, this.data.totalData.zn).start();
      },
      deep: true
    },
    xb: {
      handler() {
        new CountUp(this.$refs.isXiBei, this.data.totalData.xb).start();
      },
      deep: true
    }
  },
  methods: {
    loadData() {
      // 使用then，以至于让前面的函数执行完了才执行then后面的函数，实现异步
      getVisualization().then(response => {
        // 获取数据
        this.data = response;
        this.totalNum = this.data.totalData.total;
        this.hb = this.data.totalData.hb;
        this.db = this.data.totalData.db;
        this.hd = this.data.totalData.hd;
        this.zn = this.data.totalData.zn;
        this.xn = this.data.totalData.xn;
        this.xb = this.data.totalData.xb;
        // 打印数据
        console.log('从服务器摘取数据成功！');
        // console.log(this.data)
        // 出错则捕获错误（比如被拒绝）
      }).catch(error => {
        console.error('从服务器摘取数据失败：', error);
        // 弹窗提示错误
        this.$bvToast.toast(`下载失败：${error.message}`, {
          title: '错误',
          variant: 'danger',
          solid: true,
        });
      });
    },
    renderHorizBar() {
      const horizBarOptions = require('../assets/json/horizBarOptions.json');
      // 指定一些独特的特征
      horizBarOptions.xAxis.max = function (value) {
        return parseInt(value.max * 1.2);
      };
      horizBarOptions.yAxis.data = this.data.regionData.regions.map((item) => item.name);
      horizBarOptions.series[0].data = this.data.regionData.regions.map((item) => ({
        name: item.name,
        value: item.value,
      }));
      this.horizBarChart.setOption(horizBarOptions);
    },
    renderVertBar() {
      const vertBarOptions = require('../assets/json/vertBarOptions.json');
      // 指定一些独特的特征
      vertBarOptions.xAxis.data = this.data.serverData.servers.map((item) => item.name);
      vertBarOptions.yAxis.max = function (value) {
        return parseInt(value.max * 1.2);
      };
      vertBarOptions.series[0].data = this.data.serverData.servers.map((item) => ({
        name: item.name,
        value: item.value,
      }));
      this.vertBarChart.setOption(vertBarOptions, true);
    },
    renderRadar() {
      const radarOptions = require('../assets/json/radarOptions.json');
      // =>后面，是对象则加多一个括号，是函数就不加括号
      radarOptions.radar.indicator = this.data.riskData.risks.map((item) => ({
        name: item.name,
        max: 100
      }));
      radarOptions.series[0].data[0].value = this.data.riskData.risks.map((item) => item.value)
      this.radarChart.setOption(radarOptions, true);
    },
    renderWordCloud() {
      const WCOptions = require('../assets/json/WCOptions.json');
      // 设定字体颜色为随机
      const randomColor = () => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgb(${r},${g},${b})`
      }
      WCOptions.series[0].textStyle.color = randomColor
      WCOptions.series[0].data = this.data.wordCloudData.datas
      this.wordCloud.setOption(WCOptions, true);
    },
    renderLiquidFill() {
      const liquidFillOptions = require('../assets/json/liquidFillOptions.json');
      const proportion = this.data.proportionData.datas[0];
      liquidFillOptions.series[0].data[0] = proportion / 100;
      liquidFillOptions.series[0].label.formatter = "健康机床\n占" + proportion.toFixed(0) + '%';
      this.liquidFill.setOption(liquidFillOptions, true);
    },
    startCountUp() {
      new CountUp(this.$refs.isTotalNum, this.data.totalData.total).start();
      new CountUp(this.$refs.isHuaBei, this.data.totalData.hb).start();
      new CountUp(this.$refs.isZhongNan, this.data.totalData.zn).start();
      new CountUp(this.$refs.isDongBei, this.data.totalData.db).start();
      new CountUp(this.$refs.isXiNan, this.data.totalData.xn).start();
      new CountUp(this.$refs.isHuaDong, this.data.totalData.hd).start();
      new CountUp(this.$refs.isXiBei, this.data.totalData.xb).start();
    },
  },
  computed: {
    isSmallScreen() {
      return window.innerWidth < 768; // 假设 768px 以下是小屏
    },
    isMediumScreen() {
      return window.innerWidth < 992 && window.innerWidth >= 768; // 假设 768px 以上是大屏
    },
    isLargeScreen() {
      return window.innerWidth >= 992
    }
  }
}
</script>

<style scoped>
/*导入本地字体*/
@font-face {
  font-family: 'Electronic-Font';
  /* 这是你将要使用的字体名称 */
  src: url('../assets/fonts/FX-LED.ttf');
  font-weight: 140%;
  /* 字体粗细 */
  font-style: normal;
  /* 字体样式 */
  font-size: 100%;
}

.sidegraph,
.footgraph {
  background-color: rgba(255, 255, 255, 0.724);
}

.sidecell {
  height: 40%;
}

.middlegraph {
  background-image: url('../assets/imgs/jichuang.webp');
  background-size: 40% 40%;
  background-position: 50% 80%;
  background-repeat: no-repeat;
  /* 背景未填充区域使用单色 */
  background-color: rgba(255, 255, 255, 0.444);
}

.totalnum {
  background-image: url('../assets/imgs/mainborder.webp');
  background-repeat: no-repeat;
  background-size: 100% 75%;
}

.the-custom-list .list-group-item {
  background-color: transparent;
  border: none;
  font-family: cursive;
  font-size: 150%;
}

.the-custom-list span {
  font-family: "Electronic-Font", sans-serif;
  /* 字体与背景形成渐变，同时镂空 */
  background-clip: text;
  -webkit-text-fill-color: goldenrod;
}
</style>