<template>
  <b-container>
    <h2 align="center" class="mt-3" style="font-family:cursive;color:darkgoldenrod">
      <b-icon icon="display" class="mr-2"></b-icon>单机床数据大屏
    </h2>
    <!-- 设定相对于视窗的尺寸为100%（满屏） -->
    <b-row class="min-vh-100">
      <b-col class="statecell">
        <b-select v-model="machine_id">
          <b-select-option v-for="(value, index) in machine_id_list" :key="index" :value="value">{{ value
            }}号机床</b-select-option>
        </b-select>
        <!-- 呼吸灯 -->
        <div class="mt-5 mb-2 breathing-light"></div>
        <b-alert show :variant="current_machine_tool_state == 'danger' ? 'danger' : 'success'"
          style="text-align:center;width:100%">{{ machine_id }}号机床的状态为<h3 style="font-weight:900">{{
            current_machine_tool_state
              == 'normal' ? '正常' :
              '故障'
          }}</h3>
        </b-alert>
      </b-col>
      <b-col>
        <!-- 文字云图需要使用第三方的包来实现（echarts-wordcloud） -->
        <p class="mt-1">机床实时状态统计</p>
        <b-row class="graphcell" ref="isWordCloud">
        </b-row>
        <p class="mt-1">机床健康占比统计</p>
        <b-row class="graphcell" ref="isLiquidFill">
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as echarts from 'echarts'
import { getVisualization } from '../api/visualization.js'
import 'echarts-wordcloud'
import 'echarts-liquidfill'

export default {
  data() {
    return {
      data: null,
      wordCloud: null,
      liquidFill: null,
      current_machine_tool_state: 'normal',
      machine_id: 1,
      machine_id_list: [1],
    }
  },
  created() {
    // 首次加载数据
    this.loadData();
  },
  mounted() {
    // 确定图表位置
    this.wordCloud = echarts.init(this.$refs.isWordCloud); // 文档云图
    this.liquidFill = echarts.init(this.$refs.isLiquidFill); // 水球图
    // 每3秒调用一次 loadData
    setInterval(this.loadData, 3000);
    this.setLightSize();
    this.setLightColor();
    window.addEventListener('resize', this.setLightSize); // 监听窗口大小变化
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.intervalId);
    if (this.wordCloud) {
      this.wordCloud.dispose();
    }
    if (this.liquidFill) {
      this.liquidFill.dispose();
    }
  },
  // 使用深度监听来侦听 data 对象中所有属性的变化
  watch: {
    data: {
      // 如果data变化，则运行下列函数更新图表
      handler() {
        this.renderWordCloud();
        this.renderLiquidFill();
        this.getMachineState();
      },
      deep: true
    },
    current_machine_tool_state: {
      handler() {
        this.setLightColor();
      }
    },
    machine_id: {
      handler() {
        this.getMachineState();
      },
      deep: true
    }
  },
  methods: {
    loadData() {
      // 使用then，以至于让前面的函数执行完了才执行then后面的函数，实现异步
      getVisualization().then(response => {
        // 获取数据
        this.data = response;
        // 打印数据
        console.log('从服务器摘取数据成功！');
        console.log(this.data);
        // 出错则捕获错误（比如被拒绝）
      }).catch(error => {
        console.error('从服务器摘取数据失败：', error);
        // 弹窗提示错误
        this.$bvToast.toast(`下载失败：${error.message}`, {
          title: '错误',
          variant: 'danger',
          solid: true,
        });
      });
    },
    renderWordCloud() {
      const WCOptions = require('../assets/json/WCOptions.json');
      // 设定字体颜色为随机
      const randomColor = () => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgb(${r},${g},${b})`
      }
      WCOptions.series[0].textStyle.color = randomColor
      WCOptions.series[0].data = this.data.machineWordCloudData.datas
      this.wordCloud.setOption(WCOptions, true);
    },
    renderLiquidFill() {
      const liquidFillOptions = require('../assets/json/liquidFillOptions.json');
      const proportion = this.data.proportionData.datas[0];
      liquidFillOptions.series[0].data[0] = proportion / 100;
      liquidFillOptions.series[0].label.formatter = "健康机床\n占" + proportion.toFixed(0) + '%';
      this.liquidFill.setOption(liquidFillOptions, true);
    },
    getMachineState() {
      const lengthOfData = this.data.machineStateData.datas.length;
      this.machine_id_list = Array.from({ length: lengthOfData }, (_, index) => index + 1);
      this.current_machine_tool_state = this.data.machineStateData.datas[this.machine_id - 1].value;
      const dangerous_machines = this.data.machineStateData.datas.filter(machine => machine.value === 'danger');
      // 如果有故障的机床，将机床编号收集到一个数组中
      if (dangerous_machines.length > 0) {
        const machine_ids = dangerous_machines.map(machine => machine.id).join(', ');
        const message = `警告：以下机床发生故障：${machine_ids}`;
        this.$bvToast.toast(message, {
          title: '机床故障通知',
          variant: 'danger',
          solid: true,
          autoHideDelay: 3000, // 3秒后自动隐藏
          toaster: 'b-toaster-bottom-right',
        });
      }
    },
    setLightSize() {
      const lightElement = this.$el.querySelector('.breathing-light');
      if (lightElement) {
        const width = lightElement.clientWidth;
        lightElement.style.height = `${width}px`;
      }
    },
    setLightColor() {
      const lightElement = this.$el.querySelector('.breathing-light');
      if (lightElement) {
        if (this.current_machine_tool_state === 'danger') {
          this.breatheRed();
        } else if (this.current_machine_tool_state === 'normal') {
          this.breatheGreen();
        }
      }
    },
    breatheGreen() {
      const lightElement = this.$el.querySelector('.breathing-light');
      const duration = 2000; // 周期为2秒
      let startTime = 0;
      const breathe = (timestamp) => {
        if (startTime === 0) {
          startTime = timestamp;
        }
        const progress = timestamp - startTime;
        const phase = progress < duration / 2 ? 0.5 : 1;
        let opacity;
        if (phase === 0.5) {
          opacity = 1 - (progress / (duration / 2));
        } else {
          opacity = (progress - duration / 2) / (duration / 2);
        }
        lightElement.style.backgroundColor = `rgba(0, 255, 0, ${opacity})`;
        // 如果时间未超过一个周期，则继续动画
        if (progress < duration) {
          requestAnimationFrame(breathe);
        } else {
          // 重置时间，准备下一个周期
          startTime = 0;
          requestAnimationFrame(breathe);
        }
      };
      requestAnimationFrame(breathe);
    },
    breatheRed() {
      const lightElement = this.$el.querySelector('.breathing-light');
      const duration = 300; // 周期为300ms
      let startTime = 0;
      const breathe = (timestamp) => {
        if (startTime === 0) {
          startTime = timestamp;
        }
        let progress = timestamp - startTime;
        const halfDuration = duration / 2;
        // 计算当前周期的进度，如果超过一个周期则重新开始
        if (progress >= duration) {
          startTime = timestamp;
          progress = 0;
        }
        // 计算透明度，前半周期渐变亮，后半周期渐变暗
        let opacity;
        if (progress < halfDuration) {
          // 前半周期，从0渐变到1
          opacity = progress / halfDuration;
        } else {
          // 后半周期，从1渐变到0
          opacity = 1 - ((progress - halfDuration) / halfDuration);
        }
        // 更新颜色
        lightElement.style.backgroundColor = `rgba(255, 0, 0, ${opacity})`;
        // 请求下一帧动画
        requestAnimationFrame(breathe);
      };
      requestAnimationFrame(breathe);
    },
  },
  computed: {
    isSmallScreen() {
      return window.innerWidth < 768; // 假设 768px 以下是小屏
    },
    isMediumScreen() {
      return window.innerWidth < 992 && window.innerWidth >= 768; // 假设 768px 以上是大屏
    },
    isLargeScreen() {
      return window.innerWidth >= 992
    }
  }
}
</script>

<style scoped>
/*导入本地字体*/
@font-face {
  font-family: 'Electronic-Font';
  /* 这是你将要使用的字体名称 */
  src: url('../assets/fonts/FX-LED.ttf');
  font-weight: 140%;
  /* 字体粗细 */
  font-style: normal;
  /* 字体样式 */
  font-size: 100%;
}

.graphcell {
  height: 30%;
}

.statecell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.breathing-light {
  width: 60%;
  border-radius: 50%;
}
</style>