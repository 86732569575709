// 用于调用axios来动态获取数据
import axios from 'axios'

// 配置基本服务对象
// const service = axios.create({
//   baseURL: 'https://api.imooc-web.lgdsunday.club/api', // 这里填入网址，如果是接受后端数据，则填入gin分发的根地址（里面包括多种post和get）
//   timeout: 5000,// 服务过期时间
// })

axios.defaults.baseURL = '/proxy_url'

const service = axios.create({
  baseURL: 'https://api-test.sleepstars.net', // 这里填入网址，如果是接受后端数据，则填入gin分发的根地址（里面包括多种post和get）
  timeout: 5000,// 服务过期时间
})

// 请求拦截器（处理用户的请求，（前端到后端，我们这里是发送））
// service.interceptors.request.use(
//   (config) => {
//     // 在请求头输入信息，以便于验证（如后端jwt中间件的Authorization）
//     config.headers.icode = 'hellosunday'
//     return config
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

// 响应拦截器（处理给予用户的响应，（后端到前端，我们这里是接收））
service.interceptors.response.use((response) => {
  const { success, message, data } = response.data
  if (success) {
    return data
  } else {
    return Promise.reject(new Error(message))
  }
})

//导出服务
export default service
// 此外还需要定义一个数据接口api，来获取数据