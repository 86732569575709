<template>
  <b-container>
    <h2 align="center" class="mt-2" style="font-family:cursive;color:darkgoldenrod;font-weight:bold"><b-icon
        icon="gear-wide-connected" class="mr-2"></b-icon>项目介绍</h2>
    <!-- 中间的流水灯大屏幕 -->
    <b-carousel id="carouselHome" indicators controls :interval="setInterval" class="carousel-fade">
      <!-- 使用循环来一次性添加流水灯的图片 -->
      <b-carousel-slide class="mt-1" v-for="(slide, index) in slides" :key="index">
        <!-- 图片部分 -->
        <template v-slot:img>
          <img v-if="isLargeScreen" width="1000" height="620" :src="slide.src" />
          <img v-if="isMediumScreen" width="600" height="400" :src="slide.src" />
          <img v-if="isSmallScreen" width="320" height="260" :src="slide.src" />
        </template>
        <!-- 图片下方的讲解 -->
        <div class="carousel-caption">
          <h2 v-if="isLargeScreen">{{ slide.caption }}</h2>
          <h3 v-if="isMediumScreen" style="margin-bottom:-20px">{{ slide.caption }}</h3>
          <h5 v-if="isSmallScreen" style="margin-bottom:-100px">{{ slide.caption }}</h5>
          <p>{{ slide.text }}</p>
        </div>
      </b-carousel-slide>
      <!-- 插入视频 -->
      <b-carousel-slide>
        <template v-slot:img>
          <b-embed type="video" aspect="16by9" controls @playing="videoPlaying = true" @pause="videoPlaying = false">
            <source :src="videoSrc" type="video/mp4">
          </b-embed>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <b-button class="mt-3" block v-b-toggle.teamIntroduction variant="outline-info"
      @click="scrollToBottom">团队构成</b-button>
    <b-collapse id="teamIntroduction">
      <b-jumbotron>
        <template v-slot:header>
          <span class="memberHeader"><b-icon icon="person-fill"></b-icon>团队成员及分工</span>
        </template>
        <template v-slot:lead>
          <hr />
          <b-badge pill variant="primary" class="mr-1">前后端开发</b-badge>
          <b-badge pill variant="primary" class="mr-2">gin+vue</b-badge>
          <br v-if="isSmallScreen" />
          <span class="mr-5 memberSpan"><a href="https://github.com/Sleepstars" target="_blank">王定罡</a>、<a
              href="https://github.com/Cold56" target="_blank">郑厚润</a></span>
          <br v-if="isMediumScreen" />
          <b-badge pill variant="primary" class="mr-1">数据采集与Linux运维</b-badge>
          <b-badge pill variant="primary" class="mr-2">python</b-badge>
          <br v-if="isSmallScreen" />
          <span class="mr-5 memberSpan"><a href="https://github.com/yunheaiwo" target="_blank">刘东桦</a>、<a
              href="https://github.com/DFhuaizhu" target="_blank">彭光灿</a></span>
          <br v-if="isSmallScreen || isMediumScreen" />
          <b-badge pill variant="primary" class="mr-2">数据分析</b-badge>
          <br v-if="isSmallScreen" />
          <span class="mr-5 memberSpan"><a href="" target="_blank">叶睿</a></span>
          <br v-if="isSmallScreen || isMediumScreen" />
          <b-badge pill variant="primary" class="mr-2">商业策划</b-badge>
          <br v-if="isSmallScreen" />
          <span class="memberSpan"><a href="" target="_blank">李彦妮</a></span>、<span class="memberSpan"><a href=""
              target="_blank">郭湘琳</a></span>
        </template>
        <hr />
        <p>请给予您对我们团队以及本网页的评价</p>
        <b-button-group>
          <b-button variant="success" class="mr-2"
            @click="vote = '谢谢您的点赞'; variantVote = 'success'; voteIcon = 'heart-fill'">赞</b-button>
          <b-button variant="outline-danger"
            @click="vote = '非常抱歉，我们会加油的'; variantVote = 'danger'; voteIcon = 'emoji-dizzy'">踩</b-button>
        </b-button-group>
        <b-alert :show="vote != ''" :variant="variantVote">{{ vote }}<b-icon :icon="voteIcon"
            animation="throb"></b-icon></b-alert>
      </b-jumbotron>
    </b-collapse>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      slides: [{
        src: require("../assets/imgs/project_introduction.webp"),
        caption: '项目总览',
        text: '',
      },
      {
        src: require("../assets/imgs/Pi.webp"),
        caption: '开发板',
        text: '',
      },
      {
        src: require("../assets/imgs/sensor.webp"),
        caption: '振动传感器',
        text: '',
      }],
      videoSrc: require("../assets/videos/data_capture.mp4"),
      videoPlaying: false,
      vote: '',
      variantVote: '',
      voteIcon: '',
    }
  },
  methods: {
    scrollToBottom() {
      //需要延时才能定位到展开后的底部
      setTimeout(() => {
        // 滚动到页面底部，使用smooth参数实现平滑滚动
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
      }, 250);
    }
  },
  computed: {
    setInterval() {
      return this.videoPlaying ? 0 : 4000
    },
    isSmallScreen() {
      return window.innerWidth < 768; // 假设 768px 以下是小屏
    },
    isMediumScreen() {
      return window.innerWidth < 992 && window.innerWidth >= 768; // 假设 768px 以上是大屏
    },
    isLargeScreen() {
      return window.innerWidth >= 992
    }
  }
}
</script>

<style scoped>
/* 确保轮播图片居中显示 */
.carousel-fade .carousel-inner .carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-caption {
  color: indianred;
  font-family: PingFang SC, HarmonyOS_Regular, Helvetica Neue, Microsoft YaHei, sans-serif;
}

.memberHeader {
  font-size: 60%;
}

.memberSpan,
.memberSpan a {
  font-family: YouYuan;
  font-size: 100%;
  color: darkgreen !important;
}
</style>