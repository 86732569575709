<template>
  <div @mouseenter="isHover = true" @mouseleave="handleMouseLeave" class="navbar-wrapper">
    <!-- 过渡效果 -->
    <transition name="slide">
      <!-- 用于隐藏的外容器 -->
      <div class="container" v-show="isHover">
        <!-- 导航条 -->
        <b-navbar toggleable="lg" class="d-flex justify-content-center bg-light border-primary border-3 border">
          <b-navbar-brand>
            <!-- 头像 -->
            <b-avatar id="popover-avatar" square :src="profiileSrc" alt="UserProfile"></b-avatar>
            <!-- 悬浮头像时出现的小卡片 -->
            <b-popover target="popover-avatar" triggers="hover" placement="top">
              <b-card @mouseenter="inCard = true" @mouseleave="inCard = false"
                :class="'d-flex flex-column border-3 border-' + currentColor" style="min-height: 150px;">
                <b-row class="d-flex align-items-center justify-content-around">
                  <b-col>
                    <b-avatar id="popover-profile" size="lg" :src="profiileSrc"></b-avatar>
                    <b-popover target="popover-profile" triggers="hover">
                      <img :src="profiileSrc" style="max-width:100%;max-height:100%" />
                    </b-popover>
                  </b-col>
                  <b-col>
                    <b-card-text class="text-nowrap h4 text-primary"><a href="https://github.com/Gujixinyun"
                        target="_blank">{{ teamName }}</a></b-card-text>
                  </b-col>
                </b-row>
                <b-card-text class="mt-3 font-weight-bold text-no-wrap">团队介绍：</b-card-text>
                <b-card-text class="mt-2 text-info">{{ Signature }}</b-card-text>
              </b-card>
            </b-popover>
            <!-- 标题 -->
            <span class="ml-4" style="color:darkcyan">{{ teamName }}</span>
          </b-navbar-brand>
          <!-- 导航条的其他选项，同时设定在小屏设备时折叠 -->
          <b-navbar-toggle target="navbarToggler"></b-navbar-toggle>
          <b-collapse id="navbarToggler" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item v-show="showHome" href="/"><b-icon icon="back" class="mr-2"></b-icon>回到主页</b-nav-item>
              <b-nav-item v-show="showScreens" class="mr-2" href="/screens"><b-icon icon="display-fill"
                class="mr-2"></b-icon>全局数据大屏</b-nav-item>
              <b-nav-item v-show="showScreen" class="mr-2" href="/screen"><b-icon icon="display"
                  class="mr-2"></b-icon>单机床数据大屏</b-nav-item>
              <b-nav-item v-show="showReport" class="mr-2" href="/report"><b-icon icon="cloud-download"
                  class="mr-2"></b-icon>生成报告</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teamName: '古机新韵',
      Signature: '一支由七个大三学生组成的挑战杯团队，正在为大创结项和挑战杯全力以赴  :)',
      colors: ['primary', 'secondary', 'success', 'danger', 'warning', 'info'],
      currentColorIndex: 0,
      profiileSrc: require('../assets/imgs/teamLogo.webp'), // 本地路径需要使用 require
      isHover: true, // 新增鼠标悬停状态
      inCard: false,
    };
  },
  computed: {
    showHome() {
      return window.location.pathname !== '/';
    },
    showScreens() {
      return window.location.pathname !== '/screens';
    },
    showScreen() {
      return window.location.pathname !== '/screen';
    },
    showReport() {
      return window.location.pathname !== '/report';
    },
    currentColor() {
      return this.colors[this.currentColorIndex];
    },
  },
  mounted() {
    this.changeBorderColor();
  },
  methods: {
    changeBorderColor() {
      this.currentColorIndex = (this.currentColorIndex + 1) % this.colors.length;
      setTimeout(this.changeBorderColor, 1000); // 每3秒改变一次颜色
    },
    handleMouseLeave() {
      // 如果鼠标不在 popover 内部，才处理离开事件（让后面的决定前面的）
      if (!this.inCard) {
        this.isHover = false;
      }
    },
  },
};
</script>

<style scoped>
/* 使颜色变化更平滑 */
.card-custom {
  transition: border-color 0.5s ease-in-out;
}

.navbar-wrapper {
  position: relative;
  min-height: 20px;
  /* 设置最小高度 */
  min-width: 100%;
  /* 设置最小宽度 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  /*设定上下移动的动画*/
  transform: translateY(-100%);
}
</style>