<template>
  <b-container>
    <h2 align="center" class="mt-3" style="font-family:cursive;color:darkgoldenrod">
      <b-icon icon="cloud-download" class="mr-2"></b-icon>生成报告
    </h2>
    <b-form-group>
      <h5>机床型号</h5>
      <b-form-input required v-model="reportData.latheModel" placeholder="请输入机床型号" type="text"></b-form-input>
      <h5>机床规格</h5>
      <b-form-input required v-model="reportData.latheSize" placeholder="请输入机床规格" type="text"></b-form-input>
      <h5>选择机床制造时间</h5>
      <b-form-datepicker required v-model="reportData.timeForProduction"></b-form-datepicker>
      <h5>选择机床安装时间</h5>
      <b-form-datepicker required v-model="reportData.timeForInstallation"></b-form-datepicker>
      <h5>选择机床编号</h5>
      <b-form-radio-group v-model="reportData.machineId">
        <b-form-radio v-for="(val, idx) in machine_id_list" :value="val" :key="idx">{{ val }}号</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <b-button variant="success" @click="exportWord" class="mr-3">生成机床报告</b-button>
    <b-button variant="primary" @click="downloadVisualization">下载当前机床数据</b-button>
    <hr />
    <!-- <p>报告文件预览</p> -->
  </b-container>
</template>

<script>
import { getVisualization } from '../api/visualization.js'
import { saveAs } from "file-saver";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import JSZipUtils from "jszip-utils";

export default {
  data() {
    return {
      data: null,
      reportData: {
        latheModel: null,
        latheSize: null,
        timeForProduction: null,
        timeForInstallation: null,
        machineId: null,
        result: null,
      },
      machine_id_list: [],
      current_machine_tool_state: 'normal',
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    // 每1秒调用一次 loadData
    setInterval(this.loadData, 1000);
  },
  methods: {
    loadData() {
      getVisualization().then(response => {
        // 获取数据
        this.data = response;
        console.log('从服务器摘取数据成功！');
        this.getMachineState();
      }).catch(error => {
        console.error('从服务器摘取数据失败：', error);
        // 弹窗提示错误
        this.$bvToast.toast(`下载失败：${error.message}`, {
          title: '错误',
          variant: 'danger',
          solid: true,
          autoHideDelay: 3000, // 3秒后自动隐藏
          toaster: 'b-toaster-bottom-right',
        });
      });
    },
    getMachineState() {
      const lengthOfData = this.data.machineStateData.datas.length;
      this.machine_id_list = Array.from({ length: lengthOfData }, (_, index) => index + 1);
      if (this.reportData.machineId != null) {
        this.current_machine_tool_state = this.data.machineStateData.datas[this.reportData.machineId - 1].value;
        if (this.current_machine_tool_state === 'normal') {
          this.reportData.result = "正常";
        }
        else if (this.current_machine_tool_state === 'danger') {
          const error_data = this.data.machineWordCloudData.datas[this.reportData.machineId - 1].name;
          const error_type = error_data.slice(error_data.length - 4)
          this.reportData.result = error_type;
        }
      }
    },
    downloadVisualization() {
      this.loadData();
      this.downloadData(this.data);
    },
    downloadData(data) {
      // 获取当前时间戳
      const timestamp = new Date().getTime();
      // 空格用于格式化，2个空格意味着在层级间会有两个空格的缩进，用于让json串更加美观
      const formattedData = JSON.stringify(data, null, 2);
      // Blob构造函数接受一个包含了要包含在Blob中的数据的数组。第二个参数指定Blob中数据的MIME类型，这里设置为'application/json'
      const blob = new Blob([formattedData], { type: 'application/json' });
      // 存储数据
      saveAs(blob, "data" + timestamp + ".json");
    },
    exportWord() {
      // 确保在表单全部填写完之后再允许输出
      if (this.validateForm) {
        try {
          const _this = this
          // 异步读取模板文件，文件在public里面
          JSZipUtils.getBinaryContent('report.docx', function (err, content) {
            // 报错（比如模板文件不存在）就提示
            if (err) {
              this.$bvToast.toast(`生成报告失败: ${err.message}`, {
                title: '错误',
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000, // 3秒后自动隐藏
                toaster: 'b-toaster-bottom-right',
              });
            }
            // 创建PizZip对象
            const zip = new PizZip(content);
            // 创建Docxtemplater对象
            const docx = new Docxtemplater(zip, {
              paragraphLoop: true,
              linebreaks: true,
            });
            // 把表单的数据传入docx
            docx.setData({
              ..._this.reportData
            });
            try {
              // 使用render来渲染文档
              docx.render();
            } catch (err) {
              console.log(err);
              this.$bvToast.toast(`生成报告失败: ${err.message}`, {
                title: '错误',
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000, // 3秒后自动隐藏
                toaster: 'b-toaster-bottom-right',
              });
              throw err;
            }
            // 输出word对象
            const output = docx.getZip().generate({
              type: "blob",
              mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const timestamp = new Date().getTime();
            saveAs(output, "机床检查报告" + timestamp + ".docx");
          });
        } catch (err) {
          // 终端报错
          console.log(err)
          // 弹窗报错
          this.$bvToast.toast(`生成报告失败: ${err.message}`, {
            title: '错误',
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000, // 3秒后自动隐藏
            toaster: 'b-toaster-bottom-right',
          });
          throw err;
        }
      } else {
        console.log('请填写完所有内容');
        this.$bvToast.toast('请填写完所有内容', {
          title: '错误',
          variant: 'danger',
          solid: true,
          autoHideDelay: 3000, // 3秒后自动隐藏
          toaster: 'b-toaster-bottom-right',
        });
      }
    },
  },
  computed: {
    latheModelState() {
      return this.reportData.latheModel ? true : false;
    },
    latheSizeState() {
      return this.reportData.latheSize ? true : false;
    },
    timeForProductionState() {
      return this.reportData.timeForProduction ? true : false;
    },
    timeForInstallationState() {
      return this.reportData.timeForInstallation ? true : false;
    },
    machineIdState() {
      return this.reportData.machineId ? true : false;
    },
    validateForm() {
      return this.latheModelState && this.latheSizeState && this.timeForProductionState && this.timeForInstallationState && this.machineIdState
    }
  },
}
</script>