<template>
  <div class="outter-container">
    <navbar-view></navbar-view>
    <!-- 使用v-if，在满足条件时才加载 -->
    <home-view v-if="showHome"></home-view>
    <screens-view v-if="showScreens"></screens-view>
    <screen-view v-if="showScreen"></screen-view>
    <report-view v-if="showReport"></report-view>
  </div>
</template>

<script>
import HomeView from './views//HomeView.vue'
import ScreenView from './views/ScreenView.vue'
import ScreensView from './views/ScreensView.vue'
import NavbarView from './views/NavbarView.vue'
import ReportView from './views/ReportView.vue'
export default {
  data() {
    return {
    }
  },
  methods: {
  },
  components: {
    NavbarView,
    HomeView,
    ScreensView,
    ScreenView,
    ReportView,
  },
  mounted() {
    // 定义一个方法来刷新网页
    const refreshPage = () => {
      window.location.reload();
    };
    // 监听窗口大小变化
    window.addEventListener('resize', refreshPage);
    // 保存事件监听器的引用，以便稍后移除
    this.resizeListener = refreshPage;
  },
  beforeDestroy() {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  },
  computed: {
    showHome() {
      return window.location.pathname === '/'
    },
    showScreens() {
      return window.location.pathname === '/screens'
    },
    showScreen() {
      return window.location.pathname === '/screen'
    },
    showReport() {
      return window.location.pathname === '/report'
    }
  }
}
</script>

<style>
body {
  background-image: url('./assets/imgs/background.webp');
  background-size: cover;
}
</style>